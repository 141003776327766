<template>
	<div class="container-fluid relative animatedParent animateOnce my-3">
		<div class="tab-pane animated fadeInUpShort show active go">
			<div class="row my-3">
				<div class="col-md-12">
					<div class="card r-0 shadow">
						<div class="card-header">
							
							
							<div class="row my-3">
								<div class="col-md-8">
									<!-- 搜索 start -->
									<div class="input-group w-100 m-3 form-inline"><div class="input-group-btn">
										
										<input v-model="query" class="form-control p-2" placeholder="搜索" type="text">
										
										<select v-model="sub_dept_id" class="form-control ml-2">
											<option value="">所有部门</option>
											<option :value="item.id" v-for="(item,index) in subdepartmentlist"
											    v-bind:key="index">{{item.department_name}}</option>
										</select>
										
										<select v-model="enabled" class="form-control ml-2">
											<option value="">所有账户状态</option>
											<option v-bind:key="index" :value="item.value"
												v-for="(item,index) in enabledlist">
												{{item.name}}
											</option>
										</select>
										
										<select v-model="status" class="form-control ml-2">
											<option value="">所有激活状态</option>
											<option v-bind:key="index" :value="item.value"
												v-for="(item,index) in statuslist">
												{{item.name}}
											</option>
										</select>
										
										<button @click="initData" type="button" class="btn btn-secondary ml-2"><i class="icon-search"></i>
										</button>
										</div>
									</div>
									<!-- 搜索 end -->
								</div>
								<div class="col-md-4 ">
									<div class="float-right m-3 form-inline">
										
										
										<div class="dropdown">
											<button 
												class="btn btn-secondary dropdown-toggle mr-2" type="button"
												id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
												aria-expanded="false">
												批量操作
											</button>
											<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
												<button @click="import2" class="dropdown-item" type="button">批量添加</button>
												<button @click="import3" class="dropdown-item" type="button">批量冻结</button>
												<button @click="import4" class="dropdown-item" type="button">批量注销</button>
											</div>
										</div>
										
										<button  @click="add" type="button" class="btn btn-secondary mr-2">创建员工</button>
										<!-- <button  @click="import2" type="button" class="btn btn-secondary ml-2">导入员工</button> -->
										
										<!-- <button  @click="issueReady" type="button" class="btn btn-secondary ">发放制度</button> -->
									</div>	
								</div>	
							</div>	
						</div>
	
						<div class="table-responsive">
							<div>
								<table class="table table-striped table-hover r-0 mb-0">
									<thead>
										<tr class="no-b">
											<th style="width: 60px">
												<!-- <div class="custom-control custom-checkbox">
													<input  v-model="check_all"  @click.stop="checkAll" 
													type="checkbox" id="checkedAll" class="custom-control-input">
													<label class="custom-control-label" for="checkedAll"></label>
												</div> -->
												 <div class="d-none d-lg-block">序号</div>
											</th>
											<th>
												<div class="d-none d-lg-block">部门</div>
											</th>
											
											<th>
												<div class="d-none d-lg-block">工号</div>
											</th>
											
											<th>
												<div class="d-none d-lg-block">姓名</div>
											</th>
											
											<th>
												<div class="d-none d-lg-block">手机号码</div>
											</th>
											<!-- <th>
												<div class="d-none d-lg-block">邮箱</div>
											</th> -->
											
											
											
											<th>
												<div class="d-none d-lg-block">企业码员工ID</div>
											</th>
											
											<th>
												<div class="d-none d-lg-block">账户状态</div>
											</th>
											
											<th>
												<div class="d-none d-lg-block">激活状态</div>
											</th>
											
											<th>
												<div class="d-none d-lg-block">创建日期</div>
											</th>
											
											<th></th>
											<th></th>
										</tr>
									</thead>
	
									<tbody>
										<tr v-for="(item,index) in stafflist" v-bind:key="index" v-show="item.is_default != 1" 
										:class="{'blue lighten-5':selectedId == item.id}">
											<td>
												<!-- <div class="custom-control custom-checkbox">
													<input 
													v-model="checkedIds" :value="item.id" :id="index" 
													:disabled="item.status != 'ACTIVATED' || item.enabled < 1 "
													type="checkbox" class="custom-control-input checkSingle"  required="">
													<label class="custom-control-label" :for="index"></label>
												</div> -->
												<div class="d-none d-lg-block">{{index+1}}</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">
												<template v-if="subdeptmap.hasOwnProperty(item.sub_dept_id)">
													{{subdeptmap[item.sub_dept_id].department_name}}
												</template>
												</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">{{item.employee_no}}</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">{{item.employee_name}}
												<br>
												<template v-for="(tag,indexx) in item.taglist">
												<label class="badge badge-pill badge-info mr-2" v-bind:key="indexx" >{{tag}}</label>
												</template>
												</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">{{item.employee_mobile}}</div>
											</td>
											
											<!-- <td>
												<div class="d-none d-lg-block">{{item.employee_email}}</div>
											</td> -->
											
											<td>
												<div class="d-none d-lg-block">{{item.employee_id}}</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">
												<template v-if="item.enabled == 1">
													<span class="text-success">正常</span>
												</template>
												<template v-if="item.enabled == -1">
													<span class="text-secondary">注销</span>
												</template>
												<template v-if="item.enabled == 0">
													<span class="text-danger">冻结</span>
												</template>
												</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">
												<template v-if="item.status == 'ACTIVATED'">
													<span class="text-success">已激活</span>
												</template>
												<template v-if="item.status == 'UNACTIVATED'">
													<span class="text-secondary">未激活</span>
												</template>
												<template v-if="item.status == 'ACTIVATING'">
													<span class="text-warning">激活中</span>
												</template>
												</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">{{item.create_date}}</div>
											</td>
											
											<td class="">
												
												<button @click.stop="edit(index)" type="button" class="btn btn-outline-primary btn-xs ml-2"> 编辑 </button>
												
												<!-- <template v-if="item.status == 'ACTIVATED'"> -->
												<button @click.stop="view(index)" type="button" class="btn btn-outline-primary btn-xs ml-2"> 查看额度 </button>
												<!-- </template> -->
												<template v-if="item.status != 'ACTIVATED'">
												<button @click.stop="removeReady(index)" type="button" class="btn btn-outline-primary btn-xs ml-2"> 删除 </button>
												</template>
																						 
											</td>
											
											<td class="">
												
												<template v-if="item.status == 'ACTIVATED'">
													<!-- <button @click.stop="sendReady(index)" type="button" class="btn btn-outline-primary btn-xs"> 发放制度 </button> -->
													
													<template v-if="item.enabled == 1">
													<button @click.stop="editStateReady(index,0)" type="button" class="btn btn-outline-danger btn-xs ml-2"> 冻结 </button>
													</template>	
													
													<template v-if="item.enabled == 0">
													<button @click.stop="editStateReady(index,1)" type="button" class="btn btn-outline-primary btn-xs ml-2"> 解冻 </button>
													<button @click.stop="editStateReady(index,-1)" type="button" class="btn btn-outline-danger btn-xs ml-2"> 注销 </button>
													</template>		
												</template>	
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
	
			<div class="row">
				<div class="col-sm-12 col-md-5">
					<div class="dataTables_info" id="example2_info" role="status" aria-live="polite">
						显示  {{show_from}} ~ {{show_to}} 条 / 共 {{count}} 条
					</div>
				</div>
				<div class="col-sm-12 col-md-7">
					
					<v-pagination :total="total" :current-page='current' @pagechange="pagechange"></v-pagination>
					
				</div>
			</div>
	
		</div>
		
		<!--Add New Message Fab Button-->
		<!-- <div @click="add" class="btn-fab btn-fab-md fab-right fab-right-bottom-fixed shadow btn btn-secondary"><i class="icon-add"></i></div> -->
		
		<!-- modal start -->
		
		<!-- 员工model -->
		<div class="modal fade" id="modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		  <div class="modal-dialog">
		    <div class="modal-content">
		      <div class="modal-header">
		        <h5 class="modal-title" id="exampleModalLabel">{{selectedIndex ==-1?'添加员工':'修改员工'}}</h5>
		        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
		          <span aria-hidden="true">&times;</span>
		        </button>
		      </div>
		      <div class="modal-body">
		        <div>
					<div class="form-group">
					  <label for="" class="font-weight-bold">部门</label>
					  <select v-model="staff.sub_dept_id" class="form-control mr-2">
					  	<option :value="item.id" v-for="(item,index) in subdepartmentlist"
					  	    v-bind:key="index">{{item.department_name}}</option>
					  </select>
					  
					  <small class="form-text text-muted">
					  	与员工支付宝绑定手机号码一致
					  </small>
					</div>
					
		          <div class="form-group">
		            <label for="" class="font-weight-bold">姓名</label>
		            <input v-model="staff.employee_name" type="text" class="form-control"  placeholder="员工姓名">
		            <small class="form-text text-muted">
		            	员工支付宝实名认证姓名
		            </small>
		          </div>
		          
				  <div class="form-group" v-if="!staff.id">
				    <label for="" class="font-weight-bold">手机</label>
				    <input v-model="staff.employee_mobile" type="text" class="form-control" placeholder="手机号码">
				    <small class="form-text text-muted">
				    	与员工支付宝绑定手机号码一致
				    </small>
				  </div>
				  
				  <div class="form-group">
				    <label for="" class="font-weight-bold">工号(选填)</label>
				    <input v-model="staff.employee_no" type="text" class="form-control" placeholder="工号">
				    <small class="form-text text-muted">
						
				    </small>
				  </div>
				  
				  <div class="form-group" v-show="staff.id>0">
				    <input v-model="staff.enabled" type="checkbox" name="status" value="1" class="">
				    <label for="" class="font-weight-bold">启用</label>
				  </div>
				  
				  <div class="form-row">
				  	<div class="col-md-12 mb-3">
				  		<label for="" class="font-weight-bold">会员标签TAG</label>
				  		<br>
				  		<input  v-model="staff.tag" type="text" class="form-control" style="width: 100%;">
				  		   
				  		<small class="form-text text-muted">
				  			不同标签用英文逗号隔开
				  		</small>
				  	</div>
				  </div>
				  
		        </div>
		      </div>
		      <div class="modal-footer">
		        <button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
		        <button v-if="selectedIndex != -1 && staff.status != 'ACTIVATED' " @click="removeReady(selectedIndex)" type="button" class="btn btn-danger"> 删除 </button>
				<button @click="save" :disabled="disabled" type="button" class="btn btn-primary"> 保存 </button>
		      </div>
		    </div>
		  </div>
		</div>
		
		
		<!-- 发放modal -->
		<div class="modal fade" id="sModal" tabindex="-1" aria-labelledby="" aria-hidden="true">
		  <div class="modal-dialog">
		    <div class="modal-content">
		      <div class="modal-header">
		        <h5 class="modal-title">发放制度</h5>
		        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
		          <span aria-hidden="true">&times;</span>
		        </button>
		      </div>
		      <div class="modal-body">
		        <div>
					<div class="form-group">
					  <label for="">发放对象</label>
					  <div>
						  已选中{{checkedIds.length}}个员工
					  </div>
					</div>
					
		          <div class="form-group">
		            <label for="">制度</label>
		            <select v-model="selectedInstitutionIndex" @change="onChangeInstitution" class="form-control">
						<option value="-1">选择发放的制度</option>
						<template v-for="(item,index) in institutionlist">
		            	<option :value="index" 
							v-if="item.effective==1"
		            	    v-bind:key="index">{{item.institution_name}}</option>
						</template>	
		            </select>
		            
		            <small class="form-text text-muted">
		            	
		            </small>
		          </div>
				  
				  <div class="form-group" v-if="institution_id!=''">
				    <label for="">发放金额</label>
				    <input v-model="issue_quota" type="number" class="form-control"
				    placeholder="请输入发放金额" 
				    >
				    
				    <small class="form-text text-primary">
				    	发放金额不高于{{quota_total}}元
				    </small>
				  </div>
				 
				  
		        </div>
		      </div>
		      <div class="modal-footer">
		        <button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
		        <button v-if="selectedIndex != -1" @click="removeReady(selectedIndex)" type="button" class="btn btn-danger"> 删除 </button>
				<button @click="issueInstitution" :disabled="disabled" type="button" class="btn btn-primary"> 确认 </button>
		      </div>
		    </div>
		  </div>
		</div>
		
		
		<!-- 额度 modal -->
		<div class="modal fade" id="qModal" tabindex="-1" aria-labelledby="" aria-hidden="true">
		  <div class="modal-dialog">
		    <div class="modal-content">
		      <div class="modal-header">
		        <h5 class="modal-title">员工额度</h5>
		        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
		          <span aria-hidden="true">&times;</span>
		        </button>
		      </div>
		      <div class="modal-body">
		        <div>
					
					<div class="text-center mb-3">
						<h6 class="text-dark-heading">{{staff.employee_name}}</h6>
						<h2 class="font-weight-bold">
						  ￥{{total_amount}}
						</h2>
						 <h5 class="text-warning">总余额</h5>
					 </div>
				 
					 <table class="table table-hover earning-box">
						 <tbody>
							 
						 <template v-for="(item, index) in wallets"> 
						 <tr class="no-b" v-bind:key="index">
							 <td class="w-10">
								 <a href="panel-page-profile.html" class="avatar avatar-lg">
									 <img :src="item.icon" alt="">
								 </a>
							 </td>
							 <td>
								 <h5 class="text-primary font-weight-bold">{{item.name}}</h5>
								 <small class="text-muted">发放{{item.quota_list[0].toFixed(2)}}元</small>
							 </td>
							 <td>已用{{item.quota_list[2].toFixed(2)}}元</td>
							 <td style="font-weight: 400;">剩余{{item.quota_list[1].toFixed(2)}}元</td>
						 </tr>
						 </template>	
						
						 </tbody>
					 </table>
				  
		        </div>
		      </div>
		      <div class="modal-footer">
		        <button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
		      </div>
		    </div>
		  </div>
		</div>
		
		
		<!-- modal end -->
		
		
		
	</div>		
</template>

<script>
	
	import VPagination from '../components/my-page.vue'
	
export default {
    data: function() {
        return {
			title: '员工',
			
			token: '',
			
			// setting: {},
			// outletlist: [],
			
			ent_id: 0,
			dept_id: 0,
			
			ent_name: '',
			dept_name: '',
			user_role: '',
			user_name: '',
			
			stafflist: [],
			query: '',
			
			staff: {},
			selectedIndex: -1,
			
			selectedId: 0,
			
			disabled: false,
			
			subdepartmentlist: [],
			sub_dept_id: '',//企业下部门，搜索条件
			subdeptmap: {}, //key为 dept id, value为 dept
			
			current_page: 1,
			page_size: 50,
			count: 0,
			total_page: 0,
			show_from: 0,
			show_to: 0,
			
			total: 0, // 记录总条数
			display: 10, // 每页显示条数
			current: 1, // 当前的页数
			
			check_all: false,
			checkedIds: [],
			staff_ids: [],
			
			//
			institutionlist: [],
			institution_id: '',
			
			selectedInstitutionIndex: -1,
			//发放金额
			issue_quota: '',
			quota_total: '',//制度金额
			
			wallets: [],
			total_amount: 0,
			
			nonce_str: '',//发放用
			
			statuslist: [{
					name: '已激活',
					value: 'ACTIVATED'
				},
				{
					name: '未激活',
					value: 'UNACTIVATED'
				},
			],
			status: '',
			enabledlist: [{
					name: '正常',
					value: 1
				},
				{
					name: '冻结',
					value: 0
				},
				{
					name: '注销',
					value: -1
				},
			],
			enabled: '',
        }
    },
    props: {
      
    },
	
	components: {
		VPagination
	},
	
	created: function() {
		console.log('--- created --- ')
		let self = this;
		
		//1.检查登录
		let token = localStorage.getItem('TOKEN');
		// if (!token) {
		// 	return;
		// }
		self.token = token;
		
		//2.取得商户信息
		let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
		console.log(lsLoginData.dept_name)
		self.ent_id = lsLoginData.ent_id;
		console.info('--- this.$store.state ->',this.$store.state)
		
		self.dept_id = lsLoginData.dept_id;
		
		// self.ent_name = lsLoginData.ent_name;
		// self.dept_name = lsLoginData.dept_name;
		
		// self.user_name = lsLoginData.user_account;
		
		// self.setting = JSON.parse(localStorage.getItem('SETTING'));
		
		// self.outletlist = JSON.parse(localStorage.getItem('OUTLETLIST'));
		// console.log('--- outlet list ->', self.outletlist)
	},
	mounted: function() {
		console.log('--- mounted --- ')
		let self = this;
		
		//1.检查登录
		// let token = localStorage.getItem('TOKEN');
		// if (!self.token) {
		// 	console.log('--- mounted jump  --- ')
		// 	location.href = 'index.html';
		// 	return;
		// }
		
		self.init(()=>{
			self.initData();
		})
	},
    methods:{
		init(callback) {
			let self = this;
		
			//
			this.axios.get(this.GLOBAL.baseURI + 'getSubDepartmentList', {
					params: {
						token: self.token,
						dept_id: self.dept_id,//B企业主键ID
						// query: self.query,
						// current_page: self.current_page,
						// page_size: self.page_size,
					}
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					if (data.code == 200) {
						self.subdepartmentlist = data.data.departments;
						
						for (let i = 0; i < self.subdepartmentlist.length; i++) {
							let dept = self.subdepartmentlist[i];
							self.subdeptmap[dept.id] = dept;
						}
						console.log('--- dept map ->', self.subdeptmap);
						
						if (typeof callback == 'function') {
						    callback()
						}
					} 
					else if (data.code == 101) {
						//登录超时
						// location.href = "index.html";
						self.logoff();
					} 
					else {
						alert(data.message)
					}
				});
			//END
		
		},
		
    	initData() {
    		let self = this;
    	
    		self.loader = self.$loading.show();
			
    		this.axios.get(this.GLOBAL.baseURI + 'getStaffList', {
    				params: {
    					token: self.token,
    					dept_id: self.dept_id,
						sub_dept_id: self.sub_dept_id,
						enabled: self.enabled,
						status: self.status,
    					query: self.query,
    					current_page: self.current_page,
    					page_size: self.page_size,
    				}
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
					self.loader.hide();
					
    				if (data.code == 200) {
    					// self.stafflist = data.data.staff_list;
						
						let staffs = [];
						for(let i=0; i<data.data.staff_list.length; i++){
							let staff = data.data.staff_list[i];
							// console.log('--- self.subDeptObj ->',staff.dept_id, self.subDeptObj[staff.dept_id+""])
							// staff.sub_department_name = self.subDeptObj[staff.dept_id];
							let tags = staff.tag;
							staff.taglist = [];
							if(tags.length>0){
								staff.taglist = tags.split(',');
							}
							staffs.push(staff);
						}
						self.stafflist = staffs;
						console.error('--- self.stafflist ->', self.stafflist)
						
			
						self.current_page = data.data.page_num;
						self.page_size = data.data.page_size;
						self.count = data.data.total;
						self.total_page = Math.ceil(self.count / self.page_size);
						console.log('--- self.total_page ->', self.total_page)
						
						self.show_from = self.page_size * (self.current_page - 1) + 1;
						self.show_to = self.page_size * self.current_page <= self.count ? self.page_size * self
							.current_page : self.count;
						
						self.total = self.count; // 记录总条数
						self.display = self.page_size; // 每页显示条数
						self.current = self.current_page; // 当前的页数
    				} 
					else if (data.code == 403) {
						 // Vue.toasted.error( '没有权限', {
						 // 	position: 'top-center',
						 // 	duration: '2000',
						 // })
						 
						 self.$router.push({ name: '403'});
						 
						 return;
					}
					else if (data.code == 101) {
    					//登录超时
    					// location.href = "index.html";
						self.logoff();
    				} 
					else {
    					alert(data.message)
    				}
    			});
    		//END
    	
    	},
    	
    	search() {
    	
    		let self = this;
    		self.stafflist = __LIST.filter(item => item.staff_name.indexOf(self.query) != -1);
    	},
    	
    	add() {
    		var self = this;
    	
    		// location.href = 'editcategory.html';	
    	
    		self.selectedIndex = -1;
    		self.staff = {
				id: 0,
				sub_dept_id: self.subdepartmentlist[0].id,//员工归属部门ID，默认第一个
				employee_name: '',
				employee_mobile: '',
				employee_no: '',
				enabled: 1,
				staff: '',
			};
			
    		$('#modal').modal('show');
    	},
		
		view(index) {
			console.log(index);
			let self = this;
		
			// let cg = self.stafflist[index];
		
			self.selectedIndex = index;
			self.staff = Object.assign({}, self.stafflist[index]);
			self.selectedId = self.staff.id;
			
			console.log(self.selectedIndex, self.staff);
			
			self.getQuotaList(()=>{
				$('#qModal').modal('show');
			});
		},
		
		getQuotaList(callback) {
			let self = this;
		
			self.loader = self.$loading.show();
			
			this.axios.get(this.GLOBAL.baseURI + 'getQuotaCategoryList', {
					params: {
						token: self.token,
						employee_mobile: self.staff.employee_mobile,
						staff_id: self.staff.id,
						nonce_str: self.staff.nonce_str || '',
					}
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					
					self.loader.hide();
					
					if (data.code == 200) {
						
						if(data.data.wallets!=undefined){
							let wallets = data.data.wallets;
							
							for (let i = 0; i < wallets.length; i++) {
								let wallet = wallets[i];
								
								if(wallet.category=='DEFAULT'){
								  wallet.name = '弹性福利';
								  wallet.icon = 'http://www.poslite.cn/resources/panda-icon/icon/icon-w-1.png'
								}
								if(wallet.category=='MEAL'){
								  wallet.name = '餐食外卖';
								  wallet.icon = 'http://www.poslite.cn/resources/panda-icon/icon/icon-w-2.png'
								}
								if(wallet.category=='CAR'){
								  wallet.name = '交通出行';
								  wallet.icon = 'http://www.poslite.cn/resources/panda-icon/icon/icon-w-3.png'
								}
								if(wallet.category=='COMMON'){
								  wallet.name = '工会福利';
								  wallet.icon = 'http://www.poslite.cn/resources/panda-icon/icon/icon-w-4.png'
								}
							}
							console.log('--- wallets ->', wallets);
							
							self.wallets = wallets;
							self.total_amount = data.data.total_amount;
							
							if (typeof callback == 'function') {
							    callback()
							}
						}
					} 
					else if (data.code == 403) {
						 // Vue.toasted.error( '没有权限', {
						 // 	position: 'top-center',
						 // 	duration: '2000',
						 // })
						 
						 self.$router.push({ name: '403'});
						 
						 return;
					}
					else if (data.code == 101) {
						//登录超时
						// location.href = "index.html";
						self.logoff();
					} 
					else {
						alert(data.message)
					}
				});
			//END
		
		},
    	
    	edit(index) {
    		console.log(index);
    		let self = this;
    	
    		// let cg = self.stafflist[index];
    	
    		self.selectedIndex = index;
    		self.staff = Object.assign({}, self.stafflist[index]);
			self.selectedId = self.staff.id;
			
    		console.log(self.selectedIndex, self.staff);
    	
    		self.oldName = self.staff.staff_name;
    	
    		$('#modal').modal('show');
    	},
    	
    	save() {
    		let self = this;
    		console.log(self.staff);
    		// return;
    		console.log(self.oldName)
			
			if(self.disabled) return;
    	
    		//校验
    		if (!self.staff.employee_name) {
				this.$toasted.error('员工姓名不能为空', {
					position: 'top-center',
				}).goAway(2000)
    			return;
    		}
			
			if (!self.staff.employee_mobile) {
				this.$toasted.error('手机号码不能为空', {
					position: 'top-center',
				}).goAway(2000)
				return;
			}
			
			if (self.staff.employee_mobile && self.staff.employee_mobile.length!=11) {
				this.$toasted.error('手机号码格式错误', {
					position: 'top-center',
				}).goAway(2000)
				return;
			}
    	
    		//更新时，如果没有改名，不提交
    // 		if (self.selectedIndex != -1 &&
    // 			self.staff.staff_name.toLowerCase() == self.oldName.toLowerCase()) {
    // 			// alert('分类名称没有更改')
				// this.$toasted.error('名称没有更改', {
				// 	position: 'top-center',
				// }).goAway(2000)
				
    // 			return;
    // 		}
    	
    		//同名校验
    	// 	for (let i = 0; i < self.stafflist.length; i++) {
    	
    	// 		if (self.selectedIndex == i) continue; //编辑时，忽略自身
    	
    	// 		if (self.stafflist[i].employee_name.toLowerCase() == self.staff.employee_name.toLowerCase()) {
    	// 			// alert('分类名称不能不能重复');
					
					// this.$toasted.error('员工名称不能不能重复', {
					// 	position: 'top-center',
					// }).goAway(2000)
					
    	// 			// console.log(__LIST[self.selectedIndex].category_name)
    	// 			self.staff.employee_name = self.oldName;
    	// 			return;
    	// 		}
    	// 	}
    	
    	
    		self.disabled = true;
    	
    		// 组装数据
    		let postData = {
    			token: self.token,
				dept_id: self.dept_id,//B企业主键
				sub_dept_id: self.staff.sub_dept_id,//B企业部门主键
    			// enterprise_id: self.enterprise_id,//后端TOKEN中取得
    			staff_id: self.staff.id,//主键ID：添加时为0
    			employee_name: self.staff.employee_name,
				employee_mobile: self.staff.employee_mobile,
				employee_no: self.staff.employee_no,
				tag: self.staff.tag,
				enabled: self.staff.enabled?1:0,
				nonce_str: self.staff.nonce_str || '',
    		}
			
			console.log('--- postData ->',postData)
    	
    		let _url = self.selectedIndex == -1 ? 'addStaff' : 'updateStaff';
    		this.axios.get(this.GLOBAL.baseURI + _url, {
    				params: postData
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
    	
    				self.disabled = false;
    	
    				if (data.code == 200) {
						
						if(data.data.result_code == 'success'){
							let _staff = data.data.staff;
							console.log('--- 返回员工  ->',_staff)
							
							let tags = _staff.tag || '';
							 _staff.taglist = [];
							 if(tags.length>0){
							 	_staff.taglist = tags.split(',');
							 }
							
							
							if (self.selectedIndex == -1) {
								self.stafflist.unshift(_staff);
							} else {
								// self.stafflist.splice(self.selectedIndex, 1, data.data);
								self.$set(self.stafflist, self.selectedIndex, _staff)
							}
							    	
							    	
							// alert('保存成功')
							self.$toasted.success('保存成功', {
								position: 'top-center',
							}).goAway(2000)
							    	
							$('#modal').modal('hide');
						}
						else{
							self.$toasted.error(data.data.result_msg, {
								position: 'top-center',
							}).goAway(2000)
						}
    					
    				} 
					else if (data.code == 403) {
						 // Vue.toasted.error( '没有权限', {
						 // 	position: 'top-center',
						 // 	duration: '2000',
						 // })
						 
						 self.$router.push({ name: '403'});
						 
						 return;
					}
					else if (data.code == 404) {
						 self.$router.push({ name: '404'});
						 
						 return;
					}
					else if (data.code == 101) {
    					//登录超时
						$('#modal').modal('hide');
    					// location.href = "index.html";
						self.logoff();
    				} 
					else {
    					alert(data.message)
    				}
    			});
    	},
		
		
		removeReady(index){
			console.log(index);
			let self = this;
			
			self.selectedIndex = index;
			self.staff = Object.assign({}, self.stafflist[index]);
			
			self.$confirm({
				title: '警告',
				message: '删除员工后，福利额度将无法使用，确定删除？',
				button: {
					no: '取消',
					yes: '确定',
				},
				callback:(res)=>{
					console.log(res)
					if(res){
						self.remove();
					}
					
				}
			});
		},
    	
    	
    	remove() {
    		let self = this;
    		console.log(self.staff);
    		// return;
    	
    		self.disabled = true;
    	
    		// 组装数据
    		let postData = {
    			token: self.token,
    			// enterprise_id: self.enterprise_id,//后端TOKEN中取得
    			staff_id: self.staff.id,
				nonce_str: self.staff.nonce_str || '',
    		}
    	
    		this.axios.get(this.GLOBAL.baseURI + 'delStaff', {
    				params: postData
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
    	
    				self.disabled = false;
    	
    				if (data.code == 200) {
						if(data.data.result_code == 'success'){
							self.stafflist.splice(self.selectedIndex, 1);
							    	
							self.$toasted.success('删除成功', {
								position: 'top-center',
							}).goAway(2000)
							    	
							$('#modal').modal('hide');
						}
						else{
							self.$toasted.error(data.data.result_msg, {
								position: 'top-center',
							}).goAway(2000)
						}
    				} 
					else if (data.code == 403) {
						 // Vue.toasted.error( '没有权限', {
						 // 	position: 'top-center',
						 // 	duration: '2000',
						 // })
						 
						 self.$router.push({ name: '403'});
						 
						 return;
					}
					else if (data.code == 404) {
						 self.$router.push({ name: '404'});
						 
						 return;
					}
					else if (data.code == 101) {
    					//登录超时
    					// location.href = "index.html";
						self.logoff();
    				} 
					else {
    					self.$toasted.error( data.message, {
    						position: 'top-center',
    						duration: '2000',
    					})
    				}
    			});
    	},
		
		checkAll() {
			let self = this;
			self.check_all = !self.check_all;
			console.log('--- check all ---', self.check_all)
		
			if (self.check_all) {
				for (var i = 0; i < self.stafflist.length; i++) {
					if (self.stafflist[i].status != 'ACTIVATED') continue;
					if (self.stafflist[i].enabled < 1) continue;
					
					self.checkedIds.push(self.stafflist[i].id);
				}
			} else {
				self.checkedIds = [];
			}
		},
    	
    	onOutletChangeListener(e) {
    		let self = this;
    		console.log('--- outlet ID->', e)
    		self.outlet_id = e;
    	
    		self.initData();
    	},
		
		pagechange: function(currentPage) {
			console.log(currentPage);
			// ajax请求, 向后台发送 currentPage, 来获取对应的数据
			this.current_page = currentPage;
			this.initData();
		},
		
		import2(){
			var self = this;
				
			this.$router.push({ name: 'importstaffs', params: { dept_id: self.dept_id }})//【FANDY】 视为 POST 提交
			// this.$router.push({ path: 'editcustomer', query: { outlet_id: self.outlet_id }})//【FANDY】 视为 GET 提交
			
		},
		
		import3(){
			var self = this;
				
			this.$router.push({ name: 'importfreezestaffs', params: { dept_id: self.dept_id }})//【FANDY】 视为 POST 提交
			// this.$router.push({ path: 'editcustomer', query: { outlet_id: self.outlet_id }})//【FANDY】 视为 GET 提交
		},
		
		import4(){
			var self = this;
				
			this.$router.push({ name: 'importdeletestaffs', params: { dept_id: self.dept_id }})//【FANDY】 视为 POST 提交
			// this.$router.push({ path: 'editcustomer', query: { outlet_id: self.outlet_id }})//【FANDY】 视为 GET 提交
		},
		
		////
		issueReady(){
			let self = this;
			
			if(self.checkedIds.length==0) {
				self.$toasted.error('请选择要发放的员工', {
					position: 'top-center',
				}).goAway(2000)
				
				return;
			}
			
			self.initInstitutionData();
			$('#sModal').modal('show');
		},
		
		initInstitutionData() {
			let self = this;
			
			self.loader = self.$loading.show();
		
			//调用接口
			self.axios.get(self.GLOBAL.baseURI + 'getInstitutionList', {
					params: {
						token: self.token,
						dept_id: self.dept_id,//必须有，登录企业ID
						// start_date: self.start_date,//有效期，预留
						// end_date: self.end_date,
						query: '',
						current_page: 1,
						page_size: 50,
					}
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					
					self.loader.hide();
					
					if (data.code == 200) {
		
						self.institutionlist = data.data.institution_list;
						
		// 				self.start_date = data.data.start_date;
		// 				self.end_date = data.data.end_date;
		
		// 				self.current_page = data.data.page_num;
		// 				self.page_size = data.data.page_size;
		// 				self.count = data.data.total;
		// 				self.total_page = Math.ceil(self.count / self.page_size);
		// 				console.log('--- self.total_page ->', self.total_page)
		
		// 				self.show_from = self.page_size * (self.current_page - 1) + 1;
		// 				self.show_to = self.page_size * self.current_page <= self.count ? self.page_size * self
		// 					.current_page : self.count;
		
		// 				self.total = self.count; // 记录总条数
		// 				self.display = self.page_size; // 每页显示条数
		// 				self.current = self.current_page; // 当前的页数
		
					} else if (data.code == 101) {
						//登录超时
						self.logoff();
					} else {
						// alert(data.message)
						self.$toasted.error( data.message, {
							position: 'top-center',
							duration: '2000',
						})
					}
				});
			//END
		
		},
		
		onChangeInstitution(e){
			console.log('--- onChangeInstitution, e ->',e)
			let self = this;
			
			let ins = self.institutionlist[self.selectedInstitutionIndex];
			self.institution_id = ins.id;
			self.nonce_str = ins.nonce_str;
			
			self.quota_total = ins.quota_total;
			self.issue_quota = ins.quota_total;
		},
		
		//发送额度
		issueInstitution() {
			let self = this;
			
			if(self.institution_id == '') {
				self.$toasted.error('请选择发放的制度', {
					position: 'top-center',
				}).goAway(2000)
				
				return;
			}
			
			if(!self.issue_quota || self.issue_quota==0){
				self.$toasted.error( '请输入发放金额', {
					position: 'top-center',
					duration: '2000',
				})
				return;
			}
			
			if(self.issue_quota < 0 ){
				self.$toasted.error( '发放金额必须大于0', {
					position: 'top-center',
					duration: '2000',
				})
				return;
			}
			
			if(self.issue_quota > self.quota_total ){
				self.$toasted.error( '发放金额不大于制度金额'+self.quota_total+'元', {
					position: 'top-center',
					duration: '2000',
				})
				return;
			}
			
			self.staff_ids = self.checkedIds;
		
			self.disabled = true;
		
			// 组装数据
			let postData = {};//self.institution;
		
			postData['token'] = self.token;
			postData['ins_id'] = self.institution_id;
			postData["adapter_type"] = 2;//2表示指定员工
			
			postData['sub_dept_id'] = '';
			postData['staff_ids'] = JSON.stringify(self.staff_ids);
			// postData['tag'] = self.tag;//预留
			
			postData['share_mode'] = self.share_mode?1:0;
			
			//发放金额
			postData['issue_quota'] = self.issue_quota;
			
			postData['nonce_str'] = self.nonce_str;
		
			let postData2 = new FormData();
			for (var key in postData) {
				console.log(key + ':' + postData[key]);
				postData2.append(key, postData[key]);
			}
		
			//调用接口
			this.axios.post(this.GLOBAL.baseURI + 'addUserInstitutions',
					postData2
				)
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log('--- error->', error);
					// alert('保存失败')
					self.$toasted.error('发放失败', {
						position: 'top-center',
					}).goAway(2000)
		
					self.disabled = false;
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
		
					self.disabled = false;
		
					if (data.code == 200) {
						if (data.data.result_code == 'success') {
							self.$toasted.success('发放成功', {
								position: 'top-center',
							}).goAway(2000)
							
							$('#sModal').modal('hide');
		
							self.$router.push({
								path: 'userinstitutions',
								params: {
									outlet_id: self.outlet_id
								}
							})
						}
						else{
							self.$toasted.error( data.data.result_msg ,{
								position: 'top-center',
							}).goAway(2000)
						}
					} else if (data.code == 101) {
						//登录超时
						self.logoff();
					} else {
						// alert(data.message)
		
						self.$toasted.error(data.message, {
							position: 'top-center',
						}).goAway(2000)
					}
				});
			//END
		},
		
		editStateReady(index, state){
			console.log(index, state);
			let self = this;
			
			self.selectedIndex = index;
			self.staff = Object.assign({}, self.stafflist[index]);
			
			let msg = '';
			if(state == 0){
				msg = '员工账户冻结后，将无法发放新的福利，已发放的旧福利可以继续使用，确定冻结？'
			}
			else if(state == 1){
				msg = '员工账户解决后，将恢复发放新福利，确定解冻？'
			}
			else if(state == -1){
				msg = '员工账户注销后，将回收已发放的福利额度，确定注销？'
			}
			
			if(!msg) return;
			
			self.$confirm({
				title: '警告',
				message: msg,
				button: {
					no: '取消',
					yes: '确定',
				},
				callback:(res)=>{
					console.log(res)
					if(res){
						if(state == -1){//注销，即删除
							self.remove();
						}
						else{
							self.editState(state);
						}
					}
					
				}
			});
		},
		
		editState(state){
    		let self = this;
    		console.log(self.staff, state);
    		// return;
    	
    		self.disabled = true;
    	
    		// 组装数据
    		let postData = {
    			token: self.token,
    			// enterprise_id: self.enterprise_id,//后端TOKEN中取得
    			staff_id: self.staff.id,
				nonce_str: self.staff.nonce_str || '',
				state: state,//0冻结，1解冻，-1注销
    		}
    	
    		this.axios.get(this.GLOBAL.baseURI + 'updateStaffState', {
    				params: postData
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
    	
    				self.disabled = false;
    	
    				if (data.code == 200) {
						if(data.data.result_code == 'success'){
							self.staff.enabled = state;
							self.$set(self.stafflist, self.selectedIndex, self.staff);
							    	
							self.$toasted.success('修改成功', {
								position: 'top-center',
							}).goAway(2000)
						}
						else{
							self.$toasted.error(data.data.result_msg, {
								position: 'top-center',
							}).goAway(2000)
						}
    				} 
					else if (data.code == 403) {
						 // Vue.toasted.error( '没有权限', {
						 // 	position: 'top-center',
						 // 	duration: '2000',
						 // })
						 
						 self.$router.push({ name: '403'});
						 
						 return;
					}
					else if (data.code == 404) {
						 self.$router.push({ name: '404'});
						 
						 return;
					}
					else if (data.code == 101) {
    					//登录超时
    					// location.href = "index.html";
						self.logoff();
    				} 
					else {
    					self.$toasted.error( data.message, {
    						position: 'top-center',
    						duration: '2000',
    					})
    				}
    			});
    	},
    }
}

</script>

<style scoped>
	.bootstrap-tagsinputx{
		width: 100% !important;
	}
	.bootstrap-tagsinput input{
		width: 100% !important;
	}
</style>
